<script setup lang="ts">
import {RouterView} from 'vue-router'
import {setJhTheme} from '@jack-henry/jh-ui/utils/themeProvider.js'
import {$inj, $injByInterface} from '@/common/decorators/depinject'
import {QueryPropertiesService} from './app/factories/config/queryProperties.service'
import {computed, onMounted} from 'vue'
import {VsgToastContainer} from '@jack-henry/jha-vue-components'
import type IWorkspaceMetadataStore from '@/common/services/Workspace/IWorkspaceMetadataStore'
import BltToaster from '@/common/components/bltToaster/bltToaster.vue'
import {WorkflowFactory} from '@/common/services/services.module'

const workspaceMetadataStore = $injByInterface<IWorkspaceMetadataStore>('IWorkspaceMetadataStore')

const queryPropertiesService = $inj(QueryPropertiesService)

queryPropertiesService.init()
const workflowFactory = $inj(WorkflowFactory)
workflowFactory.setBeginPreloadingStatus()
const continueShowPreloader = computed(() => {
  return workflowFactory.getPreloadingStatus()
})

onMounted(() => {
  setJhTheme()
})
</script>

<template>
  <open-toolbar>
    <blt-card v-if="continueShowPreloader">
      <template v-slot:body>
        <blt-preloader></blt-preloader>
      </template>
    </blt-card>
    <RouterView v-slot="{Component}">
      <suspense timeout="0">
        <template #default>
          <component :is="Component"></component>
        </template>
      </suspense>
    </RouterView>
  </open-toolbar>
  <BltToaster v-if="workspaceMetadataStore.toast.showToast" />
  <vsg-toast-container class="toast-radius" />
  <footerComponent />
</template>
